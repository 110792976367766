<template>
    <div class="change-password-input" :style="{ '--borderColor': borderColor }">
        <p class="change-password-input-title">{{ title }}</p>
        <el-input class="change-password-input-content" :disabled="disabled" v-model="tempValue" placeholder="请输入"
            @blur="onBlur"></el-input>
    </div>
</template>

<script>
import { Input } from "element-ui";
export default {
    components: {
        elInput: Input
    },

    model: {
        prop: "value",
        event: "onValueChanged"
    },

    props: {
        title: String,
        value: String,
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        borderColor: {
            type: String,
            default: "#F6F6F6"
        }
    },

    data() {
        return {
            tempValue: ""
        }
    },

    mounted() {
        this.tempValue = this.value
    },

    watch: {
        value(value) {
            this.tempValue = value
        },

        tempValue(value) {
            this.$emit("onValueChanged", value)
        }
    },

    methods: {
        onBlur() {
            this.$emit("onBlur", this.title)
        }
    }

}
</script>

<style lang="scss" scoped>
.change-password-input {
    background-color: #F6F6F6;
    border: 1px solid var(--borderColor);
    border-radius: 6px;
    height: 32px;
    display: flex;
    align-items: center;

    &-title {
        color: #444C72;
        font-size: 14px;
        font-weight: 500;
        width: 60px;
        margin-left: 15px;
        margin-top: -2px;
    }

    &-content {
        font-size: 14px;
        flex: 1;
        margin-right: 10px;

        /deep/.el-input__inner {
            background-color: transparent;
            color: #888888;
            font-size: 14px;
            height: 32px;
            border: none;
            padding: 0px 5px;
        }
    }
}
</style>